export const APPOINTMENTS = {
  CARD_CLICK: {
    event: "appointment_card_click",
    category: "Appointment Management",
    action: "view appointment",
    label: "View of appointment",
  },
  VIEW_MODAL_OPEN: {
    event: "appointment_modal_open",
    category: "Appointment Management",
    action: "view appointment modal open",
    label: "View of appointment",
  },
  CANCEL_BTN_CLICK: {
    event: "appointment_cancel_btn_click",
    category: "Appointment Management",
    action: "Cancel button click",
    label: "Appointment Cancel button click",
  },
  CANCELLED: {
    event: "appointment_cancelled",
    category: "Appointment Management",
    action: "Cancelled",
    label: "Appointment Cancelled",
  },
  UPDATE_BTN_CLICK: {
    event: "appointment_update_btn_click",
    category: "Appointment Management",
    action: "Update button click",
    label: "Appointment Update button click",
  },
  UPDATED: {
    event: "appointment_updated",
    category: "Appointment Management",
    action: "Updated",
    label: "Appointment Updated",
  },
  NEW_APPOINTMENT_BTN_CLICK: {
    event: "new_appointment_btn_click",
    category: "Appointment Management",
    action: "New Appointment Button click",
    label: "New Appointment Button click",
  },
  NEW_APPOINTMENT_CREATED: {
    event: "new_appointment_created",
    category: "Appointment Management",
    action: "New Appointment Created",
    label: "New Appointment Created",
  },
  VIEW_CALENDER: {
    event: "view_calender",
    category: "Calender View",
    action: "View Appointments",
    label: "View Appointments",
  },
};

export const USER_INTERACTIONS = {
  LOGIN_BTN_CLICK: {
    event: "user_login_btn_click",
    category: "User Interaction",
    action: "Login Button Click",
    label: "User Login",
  },
  LOGIN_SUCCESS: {
    event: "user_login",
    category: "User Interaction",
    action: "Login Success",
    label: "User Login",
  },
  REGISTER_BTN_CLICK: {
    event: "user_register_btn_click",
    category: "User Interaction",
    action: "Register Button Click",
    label: "User Register",
  },
  REGISTER_SUCCESS: {
    event: "user_register",
    category: "User Interaction",
    action: "Register Success",
    label: "User Register",
  },
  LOGOUT: {
    event: "user_logout",
    category: "User Interaction",
    action: "Logout",
    label: "User Logout",
  },
  TOKEN_EXPIRE_LOGOUT: {
    event: "user_token_expiry_logout",
    category: "User Interaction",
    action: "Token Expiry Logout",
    label: "User Token Expiry Logout",
  },
  PROFILE_UPDATE: {
    event: "user_profile_updated",
    category: "User Interaction",
    action: "Profile Update",
    label: "User Profile Update",
  },
  PASSWORD_CHANGE: {
    event: "user_password_changed",
    category: "User Interaction",
    action: "Password Change",
    label: "User Password Change",
  },
  FORGOT_PASSWORD: {
    event: "user_forgot_password",
    category: "User Interaction",
    action: "Forgot Password",
    label: "User Forgot Password",
  },
};

export const HEADER_MENU_NAVIGATIONS = {
  HOME: {
    event: "logo_click",
    category: "Header Menu",
    action: "Logo click",
  },
  LOGIN: {
    event: "login_menu_click",
    category: "Header Menu",
    action: "Login",
  },
  ABOUT_US: {
    event: "about_us_menu_click",
    category: "Header Menu",
    action: "About Us",
  },
  DASHBOARD: {
    event: "header_menu_click",
    category: "Header Menu",
    action: "Dashboard",
  },
  CALENDAR: {
    event: "header_menu_click",
    category: "Header Menu",
    action: "Calendar",
  },
  APPOINTMENTS: {
    event: "header_menu_click",
    category: "Header Menu",
    action: "Appointments",
  },
  MY_PROFILE: {
    event: "header_menu_click",
    category: "Header Menu",
    action: "My Profile",
  },
};
