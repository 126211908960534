import {
  GET_BOOKINGS_LOADING,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_ERROR,
  CREATE_BOOKING_LOADING,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_ERROR,
  CREATE_BOOKING_CLEAR,
} from "./constants";

const initialState = {
  bookings: {
    loading: false,
    data: null,
    error: null,
  },
  createBooking: {
    loading: false,
    data: null,
    error: null,
  },
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKINGS_LOADING:
      return {
        ...state,
        bookings: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: {
          loading: false,
          data: action.bookings,
          error: null,
        },
      };

    case GET_BOOKINGS_ERROR:
      return {
        ...state,
        bookings: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case CREATE_BOOKING_LOADING:
      return {
        ...state,
        createBooking: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case CREATE_BOOKING_SUCCESS:
      console.log(action);
      return {
        ...state,
        createBooking: {
          loading: false,
          data: action.booking,
          error: null,
        },
      };

    case CREATE_BOOKING_ERROR:
      return {
        ...state,
        createBooking: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case CREATE_BOOKING_CLEAR:
      return {
        ...state,
        createBooking: {
          loading: false,
          data: null,
          error: null,
        },
      };

    default:
      return state;
  }
};

export default merchantReducer;
