import { deleteMethod, get, patch, post, put } from "./config";
import endpoints from "./endpoints";

export const signup = (data) => {
  return post(endpoints.SIGNUP, data);
};

export const signin = (data) => {
  return post(endpoints.SIGNIN, data);
};

export const forgotPassword = (data) => {
  return post(endpoints.FORGOT_PASSWORD, data);
};

export const changePassword = (data) => {
  return post(endpoints.CHANGE_PASSWORD, data);
};

export const getUserDetails = () => {
  return get(endpoints.USER_DETAILS);
};

export const getMerchantDetails = () => {
  return get(endpoints.USER_MERCHANT_DETAILS);
};

export const submitMerchantDetails = (data) => {
  return post(endpoints.SUBMIT_MERCHANT_DETAILS, data);
};

export const getPincodeDetails = (params) => {
  return get(endpoints.GET_PINCODE_DETAILS + params?.pincode);
};

export const getStdTimezonesList = () => {
  return get(endpoints.GET_GEOGRAPIES);
};

export const getAllServices = (params) => {
  return get(endpoints.GET_ALL_SERVICES + params?.merchantType);
};

export const submitMerchantPreferences = (params, data) => {
  return put(`${endpoints.SUBMIT_MERCHANT_DETAILS}/${params.merchantId}`, data);
};

export const getMerchantDetailsForDashboard = (params) => {
  return get(
    `${endpoints.GET_MERCHANT_DETAILS_FOR_DASHBOARD}/${params.merchantId}/${params.currentDate}`
  );
};

export const getBookings = (params) => {
  return get(`${endpoints.BOOKINGS}`, params);
};

export const getUsersBySearch = (params) => {
  return get(`${endpoints.USER_SEARCH}`, params);
};

export const getPetsBySearch = (params) => {
  return get(`${endpoints.PET_SEARCH}`, params);
};

export const merchantBooking = (data) => {
  return post(endpoints.MERCHANT_BOOKING, data);
};

export const getBookingDetailsByInvoice = (invoiceId, params) => {
  return get(`${endpoints.MERCHANT_BOOKING}/${invoiceId}`, params);
};

export const cancelBookingDetailsByInvoice = (invoiceId) => {
  return deleteMethod(`${endpoints.MERCHANT_BOOKING}/${invoiceId}`);
};

export const updateBookingDetails = (invoiceId, payload) => {
  return put(`${endpoints.MERCHANT_BOOKING}/${invoiceId}`, payload);
};

export const getMerchantCalenderBookings = (merchantId, params) => {
  return get(`${endpoints.MERCHANT_CALENDER}/${merchantId}`, params);
};

export const getAvailableBookingSlots = (params) => {
  return get(endpoints.GET_AVAILABLE_SLOTS, params);
};
