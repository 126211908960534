import { logoutUser } from "../utils/helpers/common";

// apiErrorHandler.js
const handleApiError = (error) => {
  if (error.response) {
    // Server responded with a non-2xx status code
    const status = error.response.status;
    const data = error.response.data;

    // Handle specific error codes or show a generic error message
    switch (status) {
      case 401:
        // Perform logout actions when encountering a 401 status code
        logoutUser(true);
        break;
      // Add more cases for other status codes, if needed
      default:
        // Handle other error cases or show a generic error message
        console.error(`API Error [${status}]:`, data);
    }
  } else if (error.request) {
    // The request was made, but no response was received
    console.error("API Error [No Response]:", error.request);
  } else {
    // Something happened in setting up the request that triggered an error
    console.error("API Error:", error.message);
  }
};

export default handleApiError;
