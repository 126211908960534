import axios from "axios";
import { LOCAL_STORAGE } from "../utils/constants/siteSettings";
import handleApiError from "./apiErrorHandler";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const getAccessToken = () => {
  const authToken = localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN);
  return authToken ? JSON.parse(authToken)?.token : null;
};

const setAccessToken = (headers) => {
  const token = getAccessToken();
  if (token) {
    headers["access-token"] = token;
  }
};

const apiRequest = async (method, url, params, data) => {
  const headers = {};

  setAccessToken(headers);

  try {
    const response = await instance({
      method,
      url,
      params,
      data,
      headers,
    });

    return response;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const get = (url, params = {}) => apiRequest("get", url, params);

export const post = (url, data, params = {}) =>
  apiRequest("post", url, params, data);

export const put = (url, data, params = {}) =>
  apiRequest("put", url, params, data);

export const patch = (url, data, params = {}) =>
  apiRequest("patch", url, params, data);

export const deleteMethod = (url, data, params = {}) =>
  apiRequest("delete", url, params, data);
