// utils/analytics.js

import { getUserAttributes } from "../helpers/common";

export const trackPageView = async (pageName, pageUrl, ...rest) => {
  const userAttributes = await getUserAttributes();
  console.log();
  window.gtag("config", process.env.REACT_APP_GA4_MEASUREMENT_ID, {
    page_title: pageName,
    page_location: pageUrl,
    page_path: window.location.pathname,
    ...rest,
    ...userAttributes,
  });
};

export const trackCustomEvent = async ({
  event = "",
  category = "",
  label = "",
  ...rest
}) => {
  try {
    // Get user attributes asynchronously
    const userAttributes = await getUserAttributes();

    const eventObj = {
      event_category: category,
      ...rest,
      ...userAttributes,
    };

    if (label) {
      eventObj["event_label"] = label;
    }
    // Send custom event to Google Analytics
    window.gtag("event", event, eventObj);
  } catch (error) {
    // Handle error gracefully (e.g., log it or display a user-friendly message)
    console.error("Error while tracking custom event:", error);
  }
};

export const setUserEvent = async ({ userId = undefined }) => {
  try {
    await window.gtag("set", { user_id: userId });
  } catch (error) {
    // Handle error gracefully (e.g., log it or display a user-friendly message)
    console.error("Error while setting userId:", error);
  }
};
