import { createBrowserHistory } from "history";

import { logOutAction } from "../../store/common/User/actions";
import { setUserEvent, trackCustomEvent } from "../analytics/init";

import { LOCAL_STORAGE } from "../constants/siteSettings";
import { USER_INTERACTIONS } from "../analytics/events";

import store from "../../store";

export const getUserDetails = async () => {
  let user = await localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN);

  if (!user) return null;

  user = JSON.parse(user);

  return user;
};

export const getUserAttributes = async () => {
  const userInfo = await getUserDetails();
  if (userInfo) {
    const { email, phone, country_code, name } = userInfo;

    return {
      user_id: phone,
      user_email: email,
      user_name: name,
      user_country_code: country_code,
    };
  } else {
    return {
      user_id: undefined,
    };
  }
};

export const logoutUser = async (isTokenExpired = false) => {
  if (localStorage.getItem(LOCAL_STORAGE.AUTH_TOKEN)) {
    await setUserEvent({ userId: undefined });
    // Trigger event based on token expiry or user click
    if (isTokenExpired) await trackCustomEvent(USER_INTERACTIONS.TOKEN_EXPIRE_LOGOUT);
    else await trackCustomEvent(USER_INTERACTIONS.LOGOUT);

    // Clear the authentication token from localStorage
    localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);

    // Get the history object using useHistory
    const history = createBrowserHistory();

    // Get the dispatch function from the Redux store
    const dispatch = store.dispatch;

    dispatch(logOutAction());
    history.replace("/");
    window?.location?.reload();
  }
};

export const classNames = (...classes) => {
  return classes.join(" ");
};

export const getErrorMessage = (error, fieldName) => {
  return error?.type === "required"
    ? `${fieldName} is required!`
    : error?.message;
};

export const validateEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase()
  );
};

// Expected format: "00:00:00 AM"
// Return format: "00:00:00" - remains same except hours will be converted.
export const convertTime12to24 = (time12h, config = { seconds: true }) => {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes, seconds] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}${config?.seconds ? `:${seconds}` : ""}`;
};

export const convertTime24to12 = (time24h, config = { seconds: true }) => {
  let [hours, minutes, seconds] = time24h.split(":");

  let newHours =
    Number(hours) === 0
      ? 12
      : Number(hours) > 12
      ? Number(hours) - 12
      : Number(hours);

  let modifier =
    Number(hours) === 0
      ? "AM"
      : Number(hours) > 0 && Number(hours) <= 11
      ? "AM"
      : Number(hours) === 12
      ? "PM"
      : Number(hours) > 12 && "PM";

  const time = `${newHours}:${minutes}${config?.seconds ? `:${seconds}` : ""}`;

  return [time, modifier].join(" ");
};

export const getTodayDate = () => {
  let yourDate = new Date();
  return yourDate.toISOString().split("T")[0];
};

export const getFutureDate = (days = 0) => {
  let yourDate = new Date();
  yourDate.setDate(yourDate.getDate() + days);

  // Modify the date further if needed
  // yourDate.setHours(0);
  // yourDate.setMinutes(0);
  // yourDate.setSeconds(0);

  return yourDate.toISOString().split("T")[0];
};

export const convertToCamelCase = (str = "") => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

//  dateStr = "2023-07-03"
export const isGreaterThanToday = (dateStr) => {
  let yourDate = new Date(dateStr);

  let today = new Date();

  // if (yourDate > today) {
  //   console.log("The date is greater than today.");
  // } else if (yourDate < today) {
  //   console.log("The date is earlier than today.");
  // } else {
  //   console.log("The date is equal to today.");
  // }
  return yourDate >= today;
};
