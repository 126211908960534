import React from "react";
import "./style.css";

import InlineLoader from "./InlineLoader";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-background"></div>
      <InlineLoader />
    </div>
  );
};

export default Loader;
