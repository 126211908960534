export const GET_MERCHANT_DETAILS_LOADING = "GET_MERCHANT_DETAILS_LOADING";
export const GET_MERCHANT_DETAILS_SUCCESS = "GET_MERCHANT_DETAILS_SUCCESS";
export const GET_MERCHANT_DETAILS_ERROR = "GET_MERCHANT_DETAILS_ERROR";

export const SUBMIT_MERCHANT_DETAILS_LOADING =
  "SUBMIT_MERCHANT_DETAILS_LOADING";
export const SUBMIT_MERCHANT_DETAILS_SUCCESS =
  "SUBMIT_MERCHANT_DETAILS_SUCCESS";
export const SUBMIT_MERCHANT_DETAILS_ERROR = "SUBMIT_MERCHANT_DETAILS_ERROR";

export const SUBMIT_MERCHANT_PREFERENCES_LOADING =
  "SUBMIT_MERCHANT_PREFERENCES_LOADING";
export const SUBMIT_MERCHANT_PREFERENCES_SUCCESS =
  "SUBMIT_MERCHANT_PREFERENCES_SUCCESS";
export const SUBMIT_MERCHANT_PREFERENCES_ERROR =
  "SUBMIT_MERCHANT_PREFERENCES_ERROR";
