const endpoints = {
  SIGNUP: "/auth/signup",
  SIGNIN: "/auth/login",
  FORGOT_PASSWORD: "/auth/forgot_password",
  CHANGE_PASSWORD: "/api/change_password",
  USER_DETAILS: "/api/user",
  USER_MERCHANT_DETAILS: "/api/user/merchants",
  SUBMIT_MERCHANT_DETAILS: "/api/merchant",
  GET_PINCODE_DETAILS: "/api/geography/pincode/",
  GET_GEOGRAPIES: "/geography/available_geographies", //  TODO: remove api/ from this endpoint.
  GET_ALL_SERVICES: "/api/merchant/services/",
  GET_MERCHANT_DETAILS_FOR_DASHBOARD: "/api/merchant/dashboard",
  BOOKINGS: "/api/merchant/bookings",
  GET_AVAILABLE_SLOTS: '/api/availability/suggested_times',
  USER_SEARCH: "/api/user/search",
  PET_SEARCH: "/api/pet/pets_by_owner",
  MERCHANT_BOOKING: "/api/merchant/booking",
  MERCHANT_CALENDER: "/api/merchant/calendar",
};

export default endpoints;
