import {
  GET_MERCHANT_DETAILS_LOADING,
  GET_MERCHANT_DETAILS_SUCCESS,
  GET_MERCHANT_DETAILS_ERROR,
  SUBMIT_MERCHANT_DETAILS_LOADING,
  SUBMIT_MERCHANT_DETAILS_SUCCESS,
  SUBMIT_MERCHANT_DETAILS_ERROR,
  SUBMIT_MERCHANT_PREFERENCES_LOADING,
  SUBMIT_MERCHANT_PREFERENCES_SUCCESS,
  SUBMIT_MERCHANT_PREFERENCES_ERROR,
} from "./constants";

const initialState = {
  merchantDetails: {
    loading: false,
    data: null,
    error: null,
  },
  submitMerchantDetails: {
    loading: false,
    data: null,
    error: null,
  },
  submitMerchantPreferences: {
    loading: false,
    data: null,
    error: null,
  },
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MERCHANT_DETAILS_LOADING:
      return {
        ...state,
        merchantDetails: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case GET_MERCHANT_DETAILS_SUCCESS:
      return {
        ...state,
        merchantDetails: {
          loading: false,
          data: action.user,
          error: null,
        },
      };

    case GET_MERCHANT_DETAILS_ERROR:
      return {
        ...state,
        merchantDetails: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case SUBMIT_MERCHANT_DETAILS_LOADING:
      return {
        ...state,
        submitMerchantDetails: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case SUBMIT_MERCHANT_DETAILS_SUCCESS:
      return {
        ...state,
        submitMerchantDetails: {
          loading: false,
          data: action.data,
          error: null,
        },
      };

    case SUBMIT_MERCHANT_DETAILS_ERROR:
      return {
        ...state,
        submitMerchantDetails: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    case SUBMIT_MERCHANT_PREFERENCES_LOADING:
      return {
        ...state,
        submitMerchantPreferences: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case SUBMIT_MERCHANT_PREFERENCES_SUCCESS:
      return {
        ...state,
        submitMerchantPreferences: {
          loading: false,
          data: action.data,
          error: null,
        },
      };

    case SUBMIT_MERCHANT_PREFERENCES_ERROR:
      return {
        ...state,
        submitMerchantPreferences: {
          loading: false,
          data: null,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default merchantReducer;
