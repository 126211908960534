import {
  SIGNUP_LOADING,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  SIGNIN_SUCCESS,
  SIGNIN_LOADING,
  SIGNIN_ERROR,
  LOGOUT_USER,
  GET_USER_DETAILS_LOADING,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
} from "./constants";

import { getUserDetails, signin, signup } from "../../../api";

import { LOCAL_STORAGE } from "../../../utils/constants/siteSettings";
import { USER_INTERACTIONS } from "../../../utils/analytics/events";

import { getMerchantDetailsAction } from "../Merchant/actions";
import { setUserEvent, trackCustomEvent } from "../../../utils/analytics/init";

export const signupAction = (data) => {
  return async (dispatch) => {
    dispatch(setSignupLoading());
    try {
      const response = await signup(data);
      if (response?.status === 200) {
        dispatch(storeSignupSuccess(response.data));
        trackCustomEvent({ ...USER_INTERACTIONS.REGISTER, ...data });
      } else {
        dispatch(storeSignupError(response.data || "Something went wrong!"));
      }
    } catch (error) {
      console.error("Signup Error:", error);
      if (error?.response?.data) {
        dispatch(storeSignupError(error?.response?.data));
      }
    }
  };
};

export const storeSignupSuccess = (data) => {
  return {
    type: SIGNUP_SUCCESS,
    data,
  };
};

export const setSignupLoading = () => {
  return {
    type: SIGNUP_LOADING,
  };
};

export const storeSignupError = (error) => {
  return {
    type: SIGNUP_ERROR,
    error,
  };
};

export const signinAction = (data) => {
  return async (dispatch) => {
    dispatch(setSigninLoading());
    try {
      const response = await signin(data);
      if (response?.status === 200) {
        if (response?.data?.success) {
          await localStorage.setItem(
            LOCAL_STORAGE.AUTH_TOKEN,
            JSON.stringify(response?.data?.data)
          );
          trackCustomEvent(USER_INTERACTIONS.LOGIN_SUCCESS);
          setUserEvent({userId: response?.data?.data?.phone});
          // dispatch(getUserDetailsAction());
          dispatch(getMerchantDetailsAction());
        }
        dispatch(storeSigninSuccess(response.data));
      } else {
        dispatch(storeSigninError(response.data || "Something went wrong!"));
      }
    } catch (error) {
      console.error("Signin Error:", error);
      if (error?.response?.data) {
        dispatch(storeSigninError(error?.response?.data));
      }
    }
  };
};

export const logOutAction = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const storeSigninSuccess = (data) => {
  return {
    type: SIGNIN_SUCCESS,
    data,
  };
};

export const setSigninLoading = () => {
  return {
    type: SIGNIN_LOADING,
  };
};

export const storeSigninError = (error) => {
  return {
    type: SIGNIN_ERROR,
    error,
  };
};

export const getUserDetailsAction = () => {
  return async (dispatch) => {
    dispatch(getUserDetailsLoading());
    try {
      const response = await getUserDetails();
      if (response?.status === 200) {
        if (response?.data?.success) {
          dispatch(getUserDetailsSuccess(response?.data?.data));
        }
      } else {
        dispatch(getUserDetailsError(response.data || "Something went wrong!"));
      }
    } catch (error) {
      console.error("Get User Details Error:", error);
      if (error?.response?.data) {
        dispatch(getUserDetailsError(error?.response?.data));
      }
    }
  };
};

export const getUserDetailsLoading = () => {
  return {
    type: GET_USER_DETAILS_LOADING,
  };
};

export const getUserDetailsSuccess = (user) => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    user,
  };
};

export const getUserDetailsError = (error) => {
  return {
    type: GET_USER_DETAILS_ERROR,
    error,
  };
};
