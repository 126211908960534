import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoaderWrapper from "./../components/Loader";
import AnalyticsTracker from "../utils/analytics/AnalyticsTracker";

const Home = lazy(() => import("./Homepage"));
const CreateProfile = lazy(() => import("./CreateProfile"));
const Login = lazy(() => import("./Login"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const BusinessOnBoarding = lazy(() => import("./BusinessOnboarding"));
const BusinessPreferances = lazy(() => import("./BusinessPreferances"));
const AboutUs = lazy(() => import("./AboutUs"));
const Dashboard = lazy(() => import("./Dashboard"));
const MyProfile = lazy(() => import("./MyProfile"));
const Calender = lazy(() => import("./Calender"));
const Appointments = lazy(() => import("./Appointments"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("./TermsAndConditions"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<LoaderWrapper />}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-profile" element={<CreateProfile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/business-onboarding" element={<BusinessOnBoarding />} />
          <Route
            path="/business-preferences"
            element={<BusinessPreferances />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/calendar" element={<Calender />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
        <AnalyticsTracker />
      </Router>
    </Suspense>
  );
};

export default AppRoutes;
