import {
  GET_MERCHANT_DETAILS_LOADING,
  GET_MERCHANT_DETAILS_SUCCESS,
  GET_MERCHANT_DETAILS_ERROR,
  SUBMIT_MERCHANT_DETAILS_LOADING,
  SUBMIT_MERCHANT_DETAILS_SUCCESS,
  SUBMIT_MERCHANT_DETAILS_ERROR,
  SUBMIT_MERCHANT_PREFERENCES_LOADING,
  SUBMIT_MERCHANT_PREFERENCES_SUCCESS,
  SUBMIT_MERCHANT_PREFERENCES_ERROR,
} from "./constants";

import {
  getMerchantDetails,
  submitMerchantDetails,
  submitMerchantPreferences,
} from "../../../api";

export const getMerchantDetailsAction = () => {
  return async (dispatch) => {
    dispatch(getMerchantDetailsLoading());
    try {
      const response = await getMerchantDetails();
      if (response?.status === 200) {
        if (response?.data?.success) {
          dispatch(getMerchantDetailsSuccess(response?.data?.data));
        }
      } else {
        dispatch(
          getMerchantDetailsError(response.data || "Something went wrong!")
        );
      }
    } catch (error) {
      console.error("Get User Details Error:", error);
      if (error?.response?.data) {
        dispatch(getMerchantDetailsError(error?.response?.data));
      }
    }
  };
};

export const getMerchantDetailsLoading = () => {
  return {
    type: GET_MERCHANT_DETAILS_LOADING,
  };
};

export const getMerchantDetailsSuccess = (user) => {
  return {
    type: GET_MERCHANT_DETAILS_SUCCESS,
    user,
  };
};

export const getMerchantDetailsError = (error) => {
  return {
    type: GET_MERCHANT_DETAILS_ERROR,
    error,
  };
};

export const submitMerchantDetailsAction = (data) => {
  return async (dispatch) => {
    dispatch(submitMerchantDetailsLoading());
    try {
      const response = await submitMerchantDetails(data);
      if (response?.status === 200) {
        if (response?.data?.success) {
          dispatch(submitMerchantDetailsSuccess(response?.data?.data));
        }
      } else {
        dispatch(
          submitMerchantDetailsError(response.data || "Something went wrong!")
        );
      }
    } catch (error) {
      console.error("submit User Details Error:", error);
      if (error?.response?.data) {
        dispatch(submitMerchantDetailsError(error?.response?.data));
      }
    }
  };
};

export const submitMerchantDetailsLoading = () => {
  return {
    type: SUBMIT_MERCHANT_DETAILS_LOADING,
  };
};

export const submitMerchantDetailsSuccess = (data) => {
  return {
    type: SUBMIT_MERCHANT_DETAILS_SUCCESS,
    data,
  };
};

export const submitMerchantDetailsError = (error) => {
  return {
    type: SUBMIT_MERCHANT_DETAILS_ERROR,
    error,
  };
};

export const submitMerchantPreferencesAction = (params, data) => {
  return async (dispatch) => {
    dispatch(submitMerchantPreferencesLoading());
    try {
      const response = await submitMerchantPreferences(params, data);
      if (response?.status === 200) {
        if (response?.data?.success) {
          dispatch(submitMerchantPreferencesSuccess(response?.data?.data));
        }
      } else {
        dispatch(
          submitMerchantPreferencesError(
            response.data || "Something went wrong!"
          )
        );
      }
    } catch (error) {
      console.error("submit User Details Error:", error);
      if (error?.response?.data) {
        dispatch(submitMerchantPreferencesError(error?.response?.data));
      }
    }
  };
};

export const submitMerchantPreferencesLoading = () => {
  return {
    type: SUBMIT_MERCHANT_PREFERENCES_LOADING,
  };
};

export const submitMerchantPreferencesSuccess = (data) => {
  return {
    type: SUBMIT_MERCHANT_PREFERENCES_SUCCESS,
    data,
  };
};

export const submitMerchantPreferencesError = (error) => {
  return {
    type: SUBMIT_MERCHANT_PREFERENCES_ERROR,
    error,
  };
};
