import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
// import ReactGA from "react-ga4";

import AppRoutes from "./pages/routes";

import store from "./store";

import "./assets/css/global.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <Provider store={store}>
      <AppRoutes />
      <ToastContainer />
    </Provider>
  );
};

export default App;
