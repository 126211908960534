import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { trackPageView } from "./init";

const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const pageName = location.pathname.replace("/", ""); // Example: "about" for "/about"
    trackPageView(pageName, window.location.href);
  }, [location]);

  return <></>;
};

export default AnalyticsTracker;
