export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_LOADING = "SIGNIN_LOADING";
export const SIGNIN_ERROR = "SIGNIN_ERROR";

export const GET_USER_DETAILS_LOADING = "GET_USER_DETAILS_LOADING";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";
