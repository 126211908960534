import { combineReducers } from "redux";

import merchantReducer from "./common/Merchant/reducer";
import bookingsReducer from "./common/Bookings/reducer";
import userReducer from "./common/User/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  merchant: merchantReducer,
  bookings: bookingsReducer,
});

export default rootReducer;
